/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Amplify from 'aws-amplify'
import "./layout.css"
import "./layout-override.css"

const Layout = ({ children }) => {
  return (
    <main className="fullScreen" style={{overflow: 'hidden'}}>{children}</main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.GATSBY_COGNITO_REGION,
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
})

export default Layout
