import React from 'react'
import ClassicView from '../components/ClassicView'
import { Router } from '@reach/router'
import Layout from '../components/layout-alt'

const App = ({data}) => {
  return(
    <Layout>
    <Router>
      <ClassicView path="/classic-view/:id" />
    </Router>
    </Layout>
  )
}

export default App
