import React, { Component } from 'react'

class ClassicView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sayduckId: this.props.id
    }
  }


  componentDidMount(){

  }

  render() {
    return(
      <div class="sayduck-3d-viewer-container"
      style={{
        width: '100%',
        height: '100%'
      }}
      data-product-uuid={this.state.sayduckId} data-viewer-options='{"appearance":{"background":"white"}}'>
      </div>
    )
  }
}

export default ClassicView
